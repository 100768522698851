// 0 -> Invalid Email
// 1 -> Email already registered
// 2 -> Email registered successfully

import { useContext, useState } from "react";
import Hello from "./img/Hello.png";
import "./App.css";
import Overlay from "./Overlay";
import AuthContext from "./contexts/AuthContext";
import FullScreenLoading from "./FullScreenLoading";


export default function App() {

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { googleSignIn, logOut } = useContext(AuthContext);
  async function request(data) {
    const response = await fetch("https://pu-party-backend.onrender.com", {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    const result = await response.json();
    setUser(result);
  }

  async function handleSignIn() {
    setIsLoading(true);
    try {
      let result = await googleSignIn();
      const email = result.email;
      if (email.endsWith("@poornima.edu.in"))
        request(result);
      else {
        setUser({ success: 0, ...result })
      }
    }
    catch (error) {
      setUser({ success: -1 });
    }
    setIsLoading(false);
  }
  return (
    <>
      <div className="outer">
        <div className="wrapper">
          <div className="imageCont">
            <img src={Hello} alt="" />
          </div>
          <div className="content">
            <h2 className="heading">Lakshya's Registration</h2>
            <p className="text">Use your Poornima's E-mail ID to register</p>
            <button id="btn" onClick={handleSignIn}>Sign Up</button>
          </div>
        </div>
      </div>
      <div class="credits">Created by Kunal Jain</div>
      {isLoading && <FullScreenLoading />}
      {user && <Overlay user={user} logOut={logOut} setUser={setUser}></Overlay>}
    </>
  )
}
