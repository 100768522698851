import "./FullScreenLoading.css";
export default function FullScreenLoading() {
    console.log("yes");
    return (
        <div id="full-spinner">
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
        </div>
    )
}