import { createContext } from "react";
import {
    GoogleAuthProvider,
    signInWithPopup,
    signOut
} from "firebase/auth";

import { auth } from "../firebase";

const AuthContext = createContext();
export function AuthContextProvider({ children }) {

    async function googleSignIn() {
        const provider = new GoogleAuthProvider();
        const response = await signInWithPopup(auth, provider);
        if (!response.user) return;
        const obj = {
            name: response.user.displayName,
            email: response.user.email,
            timeStamp: new Date()
        }
        return obj;
    }

    async function logOut() {
        await signOut(auth);
    }

    return (
        <AuthContext.Provider value={{ googleSignIn, logOut }}>
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContext;