import { useEffect } from "react";
export default function Overlay({ user, setUser, logOut }) {
    useEffect(() => {
        document.body.setAttribute("style", "min-height: 100vh; overflow: hidden");
        return () => {
            logOut();
            document.body.setAttribute("style", "");
        }
    }, [])
    return (
        <div className={`overlay ${user.success === 2 ? "success" : user.success === -1 ? "error" : "failure"}`}>
            <span className="close" onClick={() => setUser(null)}> &#x2716;</span>
            <div>
                {
                    user.success === 2 ?
                        <>
                            <div className="text-logo success-text">&#x2714;</div>
                            <p>
                                Enjoy the Lakshya, {user.name}!
                            </p>
                        </> :
                        user.success === 1 ?
                            <>
                                <div className="text-logo failure-text">&#x2716;</div>
                                <p>Hmm, trying to re-register, {user.name}??
                                    <span>
                                        <br /> You have already registered on
                                        <br /> {new Date(user.timeStamp).toLocaleTimeString()}
                                    </span>
                                </p>

                            </>

                            :
                            user.success === 0 ?
                                <>
                                    <div className="text-logo failure-text">&#x2716;</div>
                                    <p>Welp, only students of Poornima are allowed!</p>
                                </> :
                                <>
                                    <div className="text-logo error-text">404</div>
                                    <p>There was an Error, please try again</p>
                                </>

                }
            </div>
        </div>
    )
}